<template>
  <div class="add-coupon">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="coupon-content">
      <baseForm></baseForm>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { breadcrumbList } from './config'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import baseForm from './cpns/base-form/base-form.vue'
import { useStore } from 'vuex'

export default defineComponent({
  props: {

  },
  components: {
    Breadcrumb,
    baseForm
  },
  setup() {
    const store=useStore()

    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.add-coupon {

  .coupon-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>




