export const contentTableConfig = {
  propList: [
    { prop: 'name', label: '名称', minWidth: '150', slotName: 'name' },
    { prop: 'distributionType', label: '类型', minWidth: '100' ,slotName: 'distributionType'},
    {
      prop: 'discount',
      label: '价值',
      minWidth: '100',
      slotName: 'discount'
    },
    {
      prop: 'quantity',
      label: '裂变数量',
      minWidth: '100',
      slotName: 'quantity'
    },
    {
      prop: 'claimRestrictions',
      label: '限领次数',
      minWidth: '120',
      slotName: 'claimRestrictions'
    },
    {
      prop: 'availableShop',
      label: '可用门店',
      minWidth: '120',
      slotName: 'availableShop'
    },
    {
      prop: 'validityType',
      label: '有效期',
      minWidth: '120',
      slotName: 'validityType'
    },
    {
      prop: 'total',
      label: '剩余｜总量',
      minWidth: '120',
      slotName: 'total'
    }
  ],
  showIndexColumn: false,
  showSelectColumn: true
}
