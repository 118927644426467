<template>
  <div class="client-list">
    <pageDialog width="800px" @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="选择客户">
      <div class="main-content">
        <div class="left">
          <div class="search-wrap">
            <el-input prefix-icon="search" clearable @input="handleSearch" size="small" v-model="queryInfo.keyword" placeholder="输入关键字" />
          </div>
          <el-scrollbar height="520px">
            <div class="clent-list">
              <el-checkbox-group @change="handleCheckChange" v-model="ruleForm.checkList">
                <template v-for="item in memberInfoList">
                  <el-checkbox :label="item">
                    <div class="user-item">
                      <div class="avatar">
                        <template v-if="item.userAva!='default_ava.png'">
                          <img :src="item.userAva" alt="">
                        </template>
                        <template v-else>
                          <img src="@/assets/img/main/base/default_avatar.png" alt="">
                        </template>
                      </div>
                      <div class="uname">
                        <div class="nick">{{item.userRealname}}</div>
                        <div class="phone">{{item.userMobile}}</div>
                      </div>
                    </div>
                  </el-checkbox>
                </template>
              </el-checkbox-group>
            </div>
          </el-scrollbar>
          <el-pagination @current-change="handleCurrentChange" layout="prev, pager, next" :total="memberInfoCount" />

        </div>
        <div class="right">
          <div class="top-form">
            <div>已选客户({{ruleForm.checkList.length}})</div>
            <div @click="handleAllRemoveClick" class="remove">全部移除</div>
          </div>
          <el-scrollbar height="520px">

          <div class="opt-list">
            <template v-for="(item,index) in ruleForm.checkList">
              <div class="user-wrap">
                <div class="user-item">
                  <div class="avatar">
                    <template v-if="item.userAva!='default_ava.png'">
                      <img :src="item.userAva" alt="">
                    </template>
                    <template v-else>
                      <img src="@/assets/img/main/base/default_avatar.png" alt="">
                    </template>
                  </div>
                  <div class="uname">
                    <div class="nick">{{item.userRealname}}</div>
                    <div class="phone">{{item.userMobile}}</div>
                  </div>
                </div>
                <div @click="handleRemoveClick(index)" class="remove">删除</div>
              </div>
            </template>
          </div>
          </el-scrollbar>
        </div>
      </div>
      <div class="save-btn">
        <el-button @click="handleSaveClick" size="small" type="primary">保存</el-button>
      </div>
    </pageDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, nextTick, watch } from 'vue'
import pageDialog from '@/components/page-dialog/page-dialog.vue'
import { getUserList } from '@/service/main/customer'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'
import {
  debounce
} from 'lodash'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
  },
  components: {
    pageDialog
  },
  emits: ['changeDialogVisible', 'changeUser'],
  setup(props, { emit }) {
    const store = useStore()

    const changeDialogVisible = flag => {
      emit('changeDialogVisible', flag)
    }

    const ruleForm = reactive({
      checkList: [],
      searchValue: ''
    })

    watch(() => ruleForm.checkList, (n) => {
      // console.log('监听变化');
      // console.log(n);
    })

    const handleRemoveClick = (index) => {
      // console.log('监听删除RemoveClick');
      ruleForm.checkList.splice(index, 1)
    }


    const queryInfo = reactive({
      companyId: COMPANY_ID,
      keyword: '',
      hascard: '',
      gender: '',
      level: '',
      regStartTime: '',
      regEndTime: '',
      shopid: '',
      regfrom: '',
      page: 1
    })

    const memberInfoList = computed(() => store.state.customer.memberInfoList)
    const memberInfoCount = computed(() => store.state.customer.memberInfoCount)
    const handleCheckChange = (value) => {
      // console.log(value);
    }

    const curretOPtItem = ref()
    const handleSaveClick = () => {
      let list = ruleForm.checkList
      if (list.length == 0) {
        ElMessage({
          message: '请选择客户！',
          type: 'warning',
        })
        return
      }

      emit('changeUser', list)
      changeDialogVisible(false)
    }

    const handleAllRemoveClick = () => {
      ruleForm.checkList = []
    }

    const handleCurrentChange = page => {
      queryInfo.page = page
      initPage()
    }

    const handleSearch=debounce((value)=>{

      // console.log(value);
      initPage()
    },500)

    const initPage = () => {
      store.dispatch('customer/getmemberInfoListAction', { ...queryInfo })
    }
    initPage()

    return {
      handleSearch,
      handleAllRemoveClick,
      handleCurrentChange,
      handleRemoveClick,
      handleSaveClick,
      curretOPtItem,
      handleCheckChange,
      memberInfoCount,
      memberInfoList,
      queryInfo,
      changeDialogVisible,
      COMPANY_ID,
      ruleForm,
    }

  }
})
</script>

<style scoped lang="less">
.client-list {
  .remove {
    cursor: pointer;
  }

  .user-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .remove {
      color: rgb(133, 88, 250);
      cursor: pointer;
    }
  }

  .save-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 1000;
  }

  .main-content {
    display: flex;
    min-height: 570px;


    &:deep(.el-checkbox) {
      display: inline-block;
      height: 55px;
      display: flex;
      align-items: center;
    }

    .user-item {
      display: flex;
      align-items: center;

      .uname {
        .nick {
          margin-bottom: 4px;
        }

        .phone {
          font-size: 12px;
          color: #999;
        }
      }

      img {
        width: 36px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }

    .left {
      border-right: 2px solid rgb(245, 245, 245);
      flex: 1.4;

      .search-wrap{
        margin-bottom:10px;
        padding-right: 10px;
      }
      &:deep(.el-scrollbar) {
        height: 530px;
      }

      &:deep(.el-pagination) {
        text-align: center !important;
      }
    }

    .right {
      flex: 3;
      padding: 0 10px;

      .opt-list {
        .user-item {
          height: 55px;
        }
      }

      .top-form {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .remove {
          color: rgb(133, 88, 250);
        }
      }

    }
  }
}
</style>




