export const breadcrumbList = [
  {
    name: '营销管理',
    path: ''
  },
  {
    name: '发券宝',
    path: '/main/coupon/maketingCouponList'
  },
  {
    name: '新建定向发券',
    path: ''
  }
]

export const rules={
  name:[
    { required: true, message: '输入活动名称', trigger: 'blur' },
  ],
  distributionType:[
    { required: true, message: '选择发放时间', trigger: 'blur' },
  ],
  distributionScope:[
    { required: true, message: '选择发放对象', trigger: 'blur' },
  ],
  couponList:[
    { required: true, message: '选择优惠券', trigger: 'blur' },
  ],
  sendTime:[
    { required: true, message: '选择发放时间', trigger: 'blur' },
  ],
  dpId:[
    { required: true, message: '选择归属门店', trigger: 'blur' },
  ],
}
